<template>
  <ProfileCard
    :isLoading="isLoadingBankDocument"
    :show-edit-button="bankDetailsFullfilled"
    :title="i18n(translations.title)"
    @edit="handleUploadButtonClick"
  >
    <div v-if="!bankDetailsFullfilled">
      <p class="body-1" data-qa="myprofile-no-bank-details-description">
        {{ i18n(translations.description) }}
      </p>

      <RevButtonTiny
        v-if="displayUploadBankDetailBtn"
        class="mt-3 md:mr-3"
        data-qa="myprofile-bank-details-upload-button"
        full-width="responsive"
        :loading="isLoadingBankDocument"
        variant="secondary"
        @click="handleUploadButtonClick"
      >
        {{ i18n(translations.uploadBankDetails) }}
      </RevButtonTiny>
    </div>

    <div v-if="bankDetailsFullfilled">
      <div
        class="mb-6 space-y-2"
        data-qa="myprofile-bank-details-upload-success"
      >
        <p class="body-1-bold">
          {{ i18n(translations.bankDetailsUploadSuccess) }}
        </p>

        <p class="body-2">
          {{ i18n(translations.bankDetailsFilenameHidden) }}
        </p>
      </div>

      <div class="mb-4">
        <p class="body-1-bold mb-2">
          {{ i18n(translations.bankDetailsSecurityTitle) }}
        </p>

        <ul class="body-2 space-y-2 px-4">
          <li>{{ i18n(translations.bankDetailsSecurityBodyPart1) }}</li>
          <li>{{ i18n(translations.bankDetailsSecurityBodyPart2) }}</li>
        </ul>
      </div>

      <RevButtonTiny
        v-if="displayDeleteBankDetailBtn"
        class="mt-3 md:mr-3"
        data-qa="myprofile-bank-details-delete-button"
        full-width="responsive"
        :loading="isDeletingBankDocument"
        variant="secondaryDestructive"
        @click="handleDeleteButtonClick"
      >
        {{ i18n(translations.delete) }}
      </RevButtonTiny>
    </div>

    <input
      ref="jsFileUploadListener"
      accept=".jpeg,.jpg,.png,.pdf"
      aria-label="bankDetailsUploadInput"
      class="hidden"
      type="file"
      @change="uploadDocument"
    />

    <span class="text-action-danger-hi mt-3 block">{{ uploadError }}</span>

    <RibUploadModal
      :name="MODAL_NAMES.KYC.RIB_UPLOAD_MODAL"
      @continue-upload="handleContinueUploadButtonClick"
    />

    <RibConfirmationModal
      :confirm-button-label="i18n(translations.modaleConfirmationButtonDelete)"
      :name="MODAL_NAMES.KYC.RIB_UPLOAD_CONFIRMATION_MODAL"
      @confirmed="handleDelete"
    />
  </ProfileCard>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import {
  deleteBankDetails,
  getBankDetails,
  putBankDetails,
} from '@backmarket/http-api/src/api-specs-payout/client/missing-specs'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/dashboard/constants'

import ProfileCard from '../ProfileCard/ProfileCard.vue'
import RibConfirmationModal from '../RibConfirmationModal/RibConfirmationModal.vue'
import RibUploadModal from '../RibUploadModal/RibUploadModal.vue'

import translations from './RibCard.translations'

const BANK_DETAILS_ERROR_MESSAGE = {
  BANK_DETAILS_IBAN_INVALID: translations.ibanInvalid,
  BANK_DETAILS_BIC_INVALID: translations.bicInvalid,
  BANK_DETAILS_DOCUMENT_FORMAT_INVALID: translations.invalidFormat,
  BANK_DETAILS_DOCUMENT_SIZE_INVALID: translations.invalidSize,
  BANK_DETAILS_MISSING: translations.missingFile,
}

const MAX_FILE_SIZE_IN_BYTES = 10_000_000

const i18n = useI18n()
const logger = useLogger()
const { openErrorToast, openSuccessToast } = useTheToast()
const bankDetailsDocument = ref('')
const uploadError = ref('')
const isLoadingBankDocument = ref(false)
const isDeletingBankDocument = ref(false)
const jsFileUploadListener = ref<HTMLInputElement>()
const isBankDetailsDocumentDeletable = ref(false)

const userStore = useUserStore()

const { data } = await useHttpFetch(getBankDetails)
bankDetailsDocument.value = data?.value?.bankDetailsDocument || ''
isBankDetailsDocumentDeletable.value =
  data?.value?.isBankDetailsDocumentDeletable || false

const bankDetailsFullfilled = computed(() => {
  return bankDetailsDocument.value.length > 0
})

const displayUploadBankDetailBtn = computed(() => {
  return !userStore.user.isShadowingClient
})

const displayDeleteBankDetailBtn = computed(() => {
  return (
    !userStore.user.isShadowingClient && isBankDetailsDocumentDeletable.value
  )
})

function handleUploadButtonClick() {
  openModal(MODAL_NAMES.KYC.RIB_UPLOAD_MODAL)
}

function handleDeleteButtonClick() {
  openModal(MODAL_NAMES.KYC.RIB_UPLOAD_CONFIRMATION_MODAL)
}

async function uploadDocument({ target }: Event) {
  const { files } = target as HTMLInputElement
  const file = files?.[0]
  if (file === undefined) {
    return
  }

  if (file.size > MAX_FILE_SIZE_IN_BYTES) {
    uploadError.value = i18n(
      BANK_DETAILS_ERROR_MESSAGE.BANK_DETAILS_DOCUMENT_SIZE_INVALID,
    )

    return
  }

  const body = new FormData()
  body.append('bankDetailsDocument', file)
  isLoadingBankDocument.value = true
  try {
    const uploadResponse = await $httpFetch(putBankDetails, {
      body,
    })

    bankDetailsDocument.value = 'document'
    isBankDetailsDocumentDeletable.value =
      uploadResponse.isBankDetailsDocumentDeletable || false

    isLoadingBankDocument.value = false
    openSuccessToast({ content: i18n(translations.uploadIbanSuccess) })
  } catch (apiError) {
    const error = apiError as Error
    logger.error('[BUYBACK][RIB] Error uploading RIB', { error })
    isLoadingBankDocument.value = false
    openErrorToast({ content: i18n(translations.error) })
  }
}

function handleContinueUploadButtonClick() {
  if (jsFileUploadListener.value) {
    jsFileUploadListener.value.click()
  }
}

async function handleDelete() {
  isDeletingBankDocument.value = true
  try {
    await $httpFetch(deleteBankDetails)
    openSuccessToast({ content: i18n(translations.deleteIbanSuccess) })
  } catch (apiError) {
    const error = apiError as Error
    logger.error('[BUYBACK][RIB] Error deleting RIB', { error })
    openErrorToast({ content: i18n(translations.error) })
  } finally {
    isDeletingBankDocument.value = false
  }
}
</script>
